<template>
    <CreateDrawer />
</template>

<script>
import CreateDrawer from './components/Drawers/CreateDrawer.vue'
export default {
    components: {
        CreateDrawer
    }
}
</script>

<style>

</style>